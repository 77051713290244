import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import toast from "react-hot-toast";

const firebaseConfig = {
  apiKey: "AIzaSyC2JPBxCf7rlDCoR4Py_Qz2CMX6lXSV2gs",
  authDomain: "sbc-tanzania-31178.firebaseapp.com",
  projectId: "sbc-tanzania-31178",
  storageBucket: "sbc-tanzania-31178.appspot.com",
  messagingSenderId: "248374709014",
  appId: "1:248374709014:web:fafddfb6d5d5d0c9419e37",
  measurementId: "G-B8NFK1G4CD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

let deniedPermission = false; // Track if permission was denied

// Generate the FCM token
export const generateToken = async () => {
  try {
    // Request notification permission
    const permission = await Notification.requestPermission();

    if (permission === "denied") {
      deniedPermission = true;
      showSettingsModal(); // Show instructions modal when permission is denied
    } else if (permission === "granted") {
      deniedPermission = false;
      const token = await getToken(messaging, {
        vapidKey: 'BEi6-e-U6e3PXRhdaQTRZzZVNv_pGcHw6KRfvAGaaKebCCiLgynBsb_A0nrdBenk_fKjiHpgYiaFuiSxd0YxfZM',
      });
      localStorage.setItem("fcmToken", JSON.stringify(token));
      return token;
    }
  } catch (error) {
    console.error("Error generating FCM token:", error);
  }
};

// Show instructions for re-enabling notifications
function showSettingsModal() {
  // Create a modal or alert to show instructions
  const modal = document.createElement('div');
  modal.innerHTML = `
    <div style="position:fixed; top:0; left:0; right:0; bottom:0; background:rgba(0,0,0,0.7); display:flex; align-items:center; justify-content:center; z-index:1000;">
      <div style="background:white; padding:20px; border-radius:10px; max-width:500px; text-align:center;">
        <h2>Notification Permission Denied</h2>
        <p>Please enable notifications in your browser settings:</p>
        <ol style="text-align:left;">
          <li>Click on the lock icon near the address bar.</li>
          <li>Go to 'Site settings' or 'Notifications'.</li>
          <li>Find and enable notifications for this site.</li>
        </ol>
        <button id="closeModal" style="margin-top:10px; padding:10px 20px; background-color:#0E72AD; color:white; border:none; border-radius:5px; cursor:pointer;">Close</button>
      </div>
    </div>
  `;
  document.body.appendChild(modal);

  // Close the modal on button click
  document.getElementById('closeModal').onclick = () => {
    modal.remove();
  };
}
