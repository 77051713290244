import { createSlice } from "@reduxjs/toolkit";

const colSizeSlice = createSlice({
  name: "ColSize",
  initialState: {
    colSize: { lg: 6, md: 8 },
  },
  reducers: {
    setColSize: (state, action) => {
      state.colSize = action?.payload;
    },
  },
});

export const { setColSize } = colSizeSlice.actions;
export default colSizeSlice.reducer;

// Selectors
export const selectColSize = (state) => state.col.colSize;
