import { lazy } from "react";

const R116 = lazy(() => import("../../views/pages/Reports/R116"));
const R296 = lazy(() => import("../../views/pages/Reports/R296"));
const R782 = lazy(() => import("../../views/pages/Reports/R782/index"));
const R787 = lazy(() => import("../../views/pages/Reports/R787/index"));
const R791 = lazy(() => import("../../views/pages/Reports/R791/index"));
const R822 = lazy(() => import("../../views/pages/Reports/R822/index"));
const R820 = lazy(() => import("../../views/pages/Reports/R820/index"));
const R792 = lazy(() => import("../../views/pages/Reports/R792/index"));
const R794 = lazy(() => import("../../views/pages/Reports/R794/index"));
const R120 = lazy(() => import("../../views/pages/Reports/R120/index"));
const R238 = lazy(() => import("../../views/pages/Reports/R238/index"));
const R799 = lazy(() => import("../../views/pages/Reports/R799/index"));
const R810 = lazy(() => import("../../views/pages/Reports/R810/index"));
const R811 = lazy(() => import("../../views/pages/Reports/R811/index"));
const R815 = lazy(() => import("../../views/pages/Reports/R815/index"));
const R123 = lazy(() => import("../../views/pages/Reports/R123/index"));
const R835 = lazy(() => import("../../views/pages/Reports/R835/index"));
const R836 = lazy(() => import("../../views/pages/Reports/R836/index"));

const ReportRoutes = [
  {
    element: <R116 />,
    path: "/R116",
    route: "R116",
    slug: "R116",
    title: "R116",
  },
  {
    element: <R835 />,
    path: "/R835",
    route: "R835",
    slug: "R835",
    title: "R835",
  },
  {
    element: <R836 />,
    path: "/R836",
    route: "R836",
    slug: "R836",
    title: "R836",
  },
  {
    element: <R296 />,
    path: "/R296",
    route: "R296",
    slug: "R296",
    title: "R296",
  },
  {
    element: <R116 />,
    path: "/R116",
    route: "R116",
    slug: "R116",
    title: "R116",
  },
  {
    element: <R782 />,
    path: "/R782",
    route: "R782",
    slug: "R782",
    title: "R782",
  },
  {
    element: <R787 />,
    path: "/R787",
    route: "R787",
    slug: "R787",
    title: "R787",
  },
  {
    element: <R799 />,
    path: "/R799",
    route: "R799",
    slug: "R799",
    title: "R799",
  },
  {
    element: <R810 />,
    path: "/R810",
    route: "R810",
    slug: "R810",
    title: "R810",
  },
  {
    element: <R811 />,
    path: "/R811",
    route: "R811",
    slug: "R811",
    title: "R811",
  },
  {
    element: <R815 />,
    path: "/R815",
    route: "R815",
    slug: "R815",
    title: "R815",
  },
  {
    element: <R123 />,
    path: "/R123",
    route: "R123",
    slug: "R123",
    title: "R123",
  },
  {
    element: <R791 />,
    path: "/R791",
    route: "R791",
    slug: "R791",
    title: "R791",
  },
  {
    element: <R822 />,
    path: "/R822",
    route: "R822",
    slug: "R822",
    title: "R822",
  },
  {
    element: <R820 />,
    path: "/R820",
    route: "R820",
    slug: "R820",
    title: "R820",
  },
  {
    element: <R238 />,
    path: "/R238",
    route: "R238",
    slug: "R238",
    title: "R238",
  },
  {
    element: <R120 />,
    path: "/R120",
    route: "R120",
    slug: "R120",
    title: "R120",
  },
  {
    element: <R792 />,
    path: "/R792",
    route: "R792",
    slug: "R792",
    title: "R792",
  },
  {
    element: <R794 />,
    path: "/R794",
    route: "R794",
    slug: "R794",
    title: "R794",
  },
];
export default ReportRoutes;
