import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "UserSlice",
  initialState: {
    userList: [],
  },
  reducers: {
    setUserSlice: (state, action) => {
      state.userList = action?.payload;
    },
  },
});

export const { setUserSlice } = userSlice.actions;
export default userSlice.reducer;

// Selectors
export const selectUserSlice = (state) => state.userSlice.userList;
