import { lazy } from "react";
const Feature793 = lazy(() => import("../../views/pages/features/Feature793"));
const Feature50 = lazy(() => import("../../views/pages/features/Feature50"));
const Feature427 = lazy(() => import("../../views/pages/features/Feature427"));
const Feature374 = lazy(() => import("../../views/pages/features/Feature374"));
const Feature423 = lazy(() => import("../../views/pages/features/Feature423"));
const Feature823 = lazy(() => import("../../views/pages/features/Feature823"));
const Feature824 = lazy(() => import("../../views/pages/features/Feature824"));
const Feature825 = lazy(() => import("../../views/pages/features/Feature825"));
const Feature826 = lazy(() => import("../../views/pages/features/Feature826"));
const Feature828 = lazy(() => import("../../views/pages/features/Feature828"));
const Feature192 = lazy(() => import("../../views/pages/features/Feature192"));
const Feature97 = lazy(() => import("../../views/pages/features/Feature97"));
const Feature51 = lazy(() => import("../../views/pages/features/Feature51"));
const Feature829 = lazy(() => import("../../views/pages/features/Feature829"));
const Feature838 = lazy(() => import("../../views/pages/features/Feature838"));
const Feature830 = lazy(() => import("../../views/pages/features/Feature830"));
const Feature837 = lazy(() => import("../../views/pages/features/Feature837"));
const Feature831 = lazy(() => import("../../views/pages/features/Feature831"));
const Feature832 = lazy(() => import("../../views/pages/features/Feature832"));
const Feature833 = lazy(() => import("../../views/pages/features/Feature833"));
const Feature82 = lazy(() => import("../../views/pages/features/Feature82"));
const Feature788 = lazy(() => import("../../views/pages/features/Feature788"));
const Feature808 = lazy(() => import("../../views/pages/features/Feature808"));
const Feature804 = lazy(() => import("../../views/pages/features/Feature804"));
const Feature816 = lazy(() => import("../../views/pages/features/Feature816"));
const Feature56 = lazy(() => import("../../views/pages/features/Feature56"));
const Feature46 = lazy(() => import("../../views/pages/features/Feature46"));
const Feature61 = lazy(() => import("../../views/pages/features/Feature61"));
const Feature334 = lazy(() => import("../../views/pages/features/Feature334"));
const Feature57 = lazy(() => import("../../views/pages/features/Feature57"));
const Feature805 = lazy(() => import("../../views/pages/features/Feature805"));
const Feature839 = lazy(() => import("../../views/pages/features/Feature839"));

const FeatureRoutes = [
  {
    element: <Feature793 />,
    path: "/Feature793",
    route: "Feature793",
    slug: "Feature793",
    title: "Feature793",
  },
  {
    element: <Feature50 />,
    path: "/Feature50",
    route: "Feature50",
    slug: "Feature50",
    title: "Feature50",
  },
  {
    element: <Feature427 />,
    path: "/Feature427",
    route: "Feature427",
    slug: "Feature427",
    title: "Feature427",
  },
  {
    element: <Feature334 />,
    path: "/Feature334",
    route: "Feature334",
    slug: "Feature334",
    title: "Feature334",
  },
  {
    element: <Feature374 />,
    path: "/Feature374",
    route: "Feature374",
    slug: "Feature374",
    title: "Feature374",
  },
  {
    element: <Feature192 />,
    path: "/Feature192",
    route: "Feature192",
    slug: "Feature192",
    title: "Feature192",
  },
  {
    element: <Feature97 />,
    path: "/Feature97",
    route: "Feature97",
    slug: "Feature97",
    title: "Feature97",
  },
  {
    element: <Feature51 />,
    path: "/Feature51",
    route: "Feature51",
    slug: "Feature51",
    title: "Feature51",
  },
  {
    element: <Feature829 />,
    path: "/Feature829",
    route: "Feature829",
    slug: "Feature829",
    title: "Feature829",
  },
  {
    element: <Feature838 />,
    path: "/Feature838",
    route: "Feature838",
    slug: "Feature838",
    title: "Feature838",
  },
  {
    element: <Feature830 />,
    path: "/Feature830",
    route: "Feature830",
    slug: "Feature830",
    title: "Feature830",
  },
  {
    element: <Feature837 />,
    path: "/Feature837",
    route: "Feature837",
    slug: "Feature837",
    title: "Feature837",
  },
  {
    element: <Feature831 />,
    path: "/Feature831",
    route: "Feature831",
    slug: "Feature831",
    title: "Feature831",
  },
  {
    element: <Feature832 />,
    path: "/Feature832",
    route: "Feature832",
    slug: "Feature832",
    title: "Feature832",
  },
  {
    element: <Feature833 />,
    path: "/Feature833",
    route: "Feature833",
    slug: "Feature833",
    title: "Feature833",
  },
  {
    element: <Feature808 />,
    path: "/Feature808",
    route: "Feature808",
    slug: "Feature808",
    title: "Feature808",
  },
  {
    element: <Feature82 />,
    path: "/Feature82",
    route: "Feature82",
    slug: "Feature82",
    title: "Feature82",
  },
  {
    element: <Feature823 />,
    path: "/Feature823",
    route: "Feature823",
    slug: "Feature823",
    title: "Feature823",
  },
  {
    element: <Feature824 />,
    path: "/Feature824",
    route: "Feature824",
    slug: "Feature824",
    title: "Feature824",
  },
  {
    element: <Feature825 />,
    path: "/Feature825",
    route: "Feature825",
    slug: "Feature825",
    title: "Feature825",
  },
  {
    element: <Feature826 />,
    path: "/Feature826",
    route: "Feature826",
    slug: "Feature826",
    title: "Feature826",
  },
  {
    element: <Feature828 />,
    path: "/Feature828",
    route: "Feature828",
    slug: "Feature828",
    title: "Feature828",
  },
  {
    element: <Feature788 />,
    path: "/Feature788",
    route: "Feature788",
    slug: "Feature788",
    title: "Feature788",
  },
  {
    element: <Feature805 />,
    path: "/Feature805",
    route: "Feature805",
    slug: "Feature805",
    title: "Feature805",
  },
  {
    element: <Feature804 />,
    path: "/Feature804",
    route: "Feature804",
    slug: "Feature804",
    title: "Feature804",
  },
  {
    element: <Feature816 />,
    path: "/Feature816",
    route: "Feature816",
    slug: "Feature816",
    title: "Feature816",
  },
  {
    element: <Feature56 />,
    path: "/Feature56",
    route: "Feature56",
    slug: "Feature56",
    title: "Feature56",
  },
  {
    element: <Feature46 />,
    path: "/Feature46",
    route: "Feature46",
    slug: "Feature46",
    title: "Feature46",
  },
  {
    element: <Feature61 />,
    path: "/Feature61",
    route: "Feature61",
    slug: "Feature61",
    title: "Feature61",
  },
  {
    element: <Feature57 />,
    path: "/Feature57",
    route: "Feature57",
    slug: "Feature57",
    title: "Feature57",
  },
  {
    element: <Feature423 />,
    path: "/Feature423",
    route: "Feature423",
    slug: "Feature423",
    title: "Feature423",
  },
  {
    element: <Feature839 />,
    path: "/Feature839",
    route: "Feature839",
    slug: "Feature839",
    title: "Feature839",
  },
];
export default FeatureRoutes;
