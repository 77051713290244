import { DefaultRoute } from "../router/routes";

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            accuracy: position.coords.accuracy,
          };
          resolve(location);
        },
        (error) => {
          reject(new Error(error.message));
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export function getTanzanianDates() {
  const today = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Africa/Dar_es_Salaam" })
  );

  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  return { today, tomorrow, yesterday };
}

export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute;
  if (userRole === "client") return "/access-control";
  return "/";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const convertIntoTwoDigitDecimal = (value) => {
  return parseFloat(value).toFixed(2);
};

export function getDisplayCurrencyFormatWithNaN(val) {
  if (isNaN(val)) return "0";
  const hasDecimal = val % 1 !== 0;
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: hasDecimal ? 2 : 0,
    maximumFractionDigits: 2,
  }).format(val);
}
export function getDisplayCurrencyFormat(val) {
  if (isNaN(val)) return "0";
  const hasDecimal = val % 1 !== 0;
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: hasDecimal ? 2 : 0,
    maximumFractionDigits: 2,
  }).format(val);
}

export const checkIsNaN = (value) => {
  return isNaN(value) ? "0" : value;
};

export const getPublicIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    console.log("Public IPv4 Address:", data.ip);
    return data.ip;
  } catch (error) {
    console.error("Error fetching public IP address:", error);
    return "0.0.0.0";
  }
};

export const calculateAverageTimestamp = (timestamps) => {
  if (timestamps.length === 0) return null;
  const totalMilliseconds = timestamps.reduce((sum, timestamp) => {
    return sum + new Date(timestamp).getTime();
  }, 0);
  const averageMilliseconds = totalMilliseconds / timestamps.length;
  return new Date(averageMilliseconds).toISOString();
};

export function convertMinutesToTime(minutes) {
  if (isNaN(minutes)) {
    return "0m 0s";
  }

  let wholeMinutes = Math.floor(minutes) || 0;
  let seconds = Math.floor((minutes - wholeMinutes) * 60) || 0;

  let minuteStr = `${wholeMinutes}m`;
  let secondStr = `${seconds}s`;

  return `${minuteStr} ${secondStr}`;
}
