import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SC } from "../../../../services/Api/serverCall";
import UserDropdown from "../../../components/Profile/ProfileDropDown";
import IntlDropdown from "./IntlDropdown";
import NotificationDropdown from "./NotificationDropdown";
import WorkFlowDropdown from "./WorkFlowDropdown";
import ChangePasswordModal from '../../../../views/components/ChangePasswordModal.js'

const NavbarUser = ({ windowWidth }) => {
  const navigate = useNavigate();
  const [changePasswordModal, setChangePasswordModal] = useState(false)

  const logOut = (e) => {
    e.preventDefault();
    navigate("/login");

    SC.getCall({ url: "logout" }).then((response) => { });
    localStorage.clear();
  };

  const profile = (e) => {
    navigate("/account-setting");
  };

  const changePassword = (e) => {
    setChangePasswordModal(true)
  };

  return (
    <>
      <ul
        className="nav  d-flex align-items-center ms-auto flex-nowrap"
        style={{ marginTop: -5 }}
      >
        <li style={{ marginBottom: 5, marginRight: 10 }}>
          <WorkFlowDropdown />
        </li>
        <li style={{ marginRight: 20 }}>
          <NotificationDropdown />
        </li>
        <li>
          <UserDropdown logOut={logOut} profile={profile} changePassword={changePassword} />
        </li>
      </ul>
      <ChangePasswordModal isOpen={changePasswordModal} toggle={() => setChangePasswordModal(false)} />
    </>
  );
};
export default NavbarUser;
