// ** React Imports
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from "react";
import {
  inventory,
  report_center,
  administration,
  sales,
  synchronization,
} from "../assets/wasfaty/SVG";
// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/HorizontalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/horizontal'

const HorizontalLayout = props => {
  const [sideBarList, setSideBarList] = useState([]);
  const iconMapping = {
    inventory: inventory,
    report_center: report_center,
    sales: sales,
    inventory: inventory,
    administration: administration,
    synchronization: synchronization,
  };
  useEffect(() => {
    getSideBarData();
  }, []);


  const getSideBarData = async () => {
    let features_groups = JSON.parse(localStorage?.getItem("features_groups"));
    if (features_groups !== null) {
      let dynamicNav = [
        ...features_groups?.feature_groups?.map((item) => ({
          id: item?.slugs,
          slug: item?.slugs,
          title: item?.groupName,
          navLink: "/" + item?.slugs + "/" + item?.groupId,
          icon: () => iconMapping[item?.slugs] || (() => [item?.slugs]),
        })),
      ];

      console.log("getSideBarData", dynamicNav);
      setSideBarList(dynamicNav);
    }
  };

  return (
    <Layout menuData={[...navigation, ...sideBarList]} {...props}>
      <Outlet />
    </Layout>
  )
}

export default HorizontalLayout
