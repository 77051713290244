import { useState, useEffect } from "react";
import { SC } from "../../services/Api/serverCall";

const useDropDown = (typeId) => {
    const [distributorGroups, setDistributorGroups] = useState([]);

    useEffect(() => {
        if (typeId) {
            getDropDownList(typeId);
        }
    }, [typeId]);

    const getDropDownList = (id) => {
        let payload = { typeId: id };
        SC.postCall({
            url: "portal/C01PortalGetDropdown",
            data: payload,
        })
            .then((res) => {
                let response = JSON.parse(JSON.stringify(res));
                console.log("RES C01PortalGetDropdown", response);
                let newFilterItems = response?.data?.DATA?.map((i) => ({
                    value: i?.label,
                    label: i?.label,
                    ...i,
                  }));
                setDistributorGroups(newFilterItems);
            })
            .catch((error) => {
                console.error("ERROR fetching distributor groups", error);
            });
    };
    return distributorGroups;
};

export default useDropDown;