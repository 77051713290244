import { useState } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Avatar from "./avatar";
import avatarImg from "./Avatar.webp";
import logoutIcon from "./logoutIcon.svg";
import "./style.scss";
import { useIntl } from "react-intl";
import { Lock, User, LogOut } from "react-feather";

const baseUrlImages = process.env.REACT_APP_IMAGES_BASEURL;


const UserDropdown = ({ logOut, profile, changePassword }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const translation = useIntl();

  let userDataMain = JSON.parse(localStorage.getItem("userData"));

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle color="white" className="m-0 p-0">
        <img
          src={`${baseUrlImages}images/userProfileImages/${userDataMain?.img_label}`}
          alt="Preview"
          style={{ maxWidth: 50, maxHeight: 50, borderRadius: 50 / 2 }}
        />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>
          <div className="d-flex ">
            <img
              src={`${baseUrlImages}images/userProfileImages/${userDataMain?.img_label}`}
              alt="Preview"
              style={{ maxWidth: 50, maxHeight: 50, borderRadius: 50 / 2 }}
            />
            <div className="user">
              <h4 className="m-0 user-name">{userDataMain?.name}</h4>
              <small className="user-status">{userDataMain?.designation}</small>
            </div>
          </div>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={profile} className="w-100">
          <div className="d-flex align-items-center">
            <User />
            <span
              className="align-middle mx-1"
              style={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {translation.formatMessage({
                id: "Profile",
                defaultMessage: "Profile",
              })}
            </span>
          </div>
        </DropdownItem>
        <DropdownItem onClick={changePassword} className="w-100">
          <div className="d-flex align-items-center">
            <Lock />
            <span
              className="align-middle mx-1"
              style={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              Change Password
            </span>
          </div>
        </DropdownItem>
        <DropdownItem onClick={logOut} className="w-100">
          <div className="d-flex align-items-center">
            <LogOut />
            <span
              className="align-middle mx-1"
              style={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {translation.formatMessage({
                id: "Logout",
                defaultMessage: "Logout",
              })}
            </span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default UserDropdown;
