import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const ConfirmationModal = ({ isOpen, onConfirm, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onClose()}
      className="modal-dialog-centered"
      backdrop={true}
    >
      <ModalHeader>
        <p style={{ fontSize: 20 }}>{message}</p>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Button
              onClick={() => {
                onClose();
              }}
              style={{ width: 200 }}
              color="warning"
            >
              No
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                onClose();
                onConfirm();
              }}
              style={{ width: 200 }}
              color="success"
            >
              Yes
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
