import { lazy } from "react";
import EditBlock from "../../views/pages/features/Feature97/EditBlock";
import AddBlock from "../../views/pages/features/Feature97/AddBlock";
const WorkFlowDetail = lazy(() => import("../../views/pages/WorkFlow/Details"));
const OutletProfile = lazy(() =>
  import("../../views/pages/features/Feature838/OutletProfile")
);
const R116 = lazy(() => import("../../views/pages/Reports/R116"));
const R782 = lazy(() => import("../../views/pages/Reports/R782"));
const WorkFlow = lazy(() => import("../../views/pages/WorkFlow"));
const PrintForm = lazy(() =>
  import("../../views/pages/features/Feature61/PrintForm/Pick")
);
const ComercialForm = lazy(() =>
  import("../../views/pages/features/Feature61/PrintForm/ComercialForm")
);
const YardReturn = lazy(() =>
  import("../../views/pages/features/Feature61/PrintForm/YardReturn")
);
const DispatchDelivery = lazy(() =>
  import("../../views/pages/AllForms/DispatchDelivery")
);
const UPCDashboardInvoice = lazy(() =>
  import("../../views/pages/AllForms/UPCDashboardInvoice")
);
const DispatchBooking = lazy(() =>
  import("../../views/pages/AllForms/DispatchBooking")
);
const DispatchMerchandising = lazy(() =>
  import("../../views/pages/AllForms/DispatchMerchandising")
);
const ClosedOutlets = lazy(() =>
  import("../../views/pages/AllForms/ClosedOutlets")
);
const AddPjps = lazy(() =>
  import("../../views/pages/features/Feature82/AddPjps")
);
const EditPjps = lazy(() =>
  import("../../views/pages/features/Feature82/EditPjps")
);
const OrderNow = lazy(() =>
  import("../../views/pages/features/Feature816/OrderNow")
);
const AccountSetting = lazy(() =>
  import("../../views/pages/profile/AccountSetting")
);

const ListRoutes = [
  {
    element: <AddPjps />,
    path: "/AddPjps",
    route: "AddPjps",
    slug: "AddPjps",
    title: "AddPjps",
  },
  {
    path: "/account-setting",
    element: <AccountSetting />,
    route: "",
    isAuth: true,
  },
  {
    element: <EditPjps />,
    path: "/EditPjps",
    route: "EditPjps",
    slug: "EditPjps",
    title: "EditPjps",
  },

  {
    element: <OrderNow />,
    path: "/OrderNow",
    route: "OrderNow",
    slug: "OrderNow",
    title: "OrderNow",
  },

  {
    element: <WorkFlow />,
    path: "/WorkFlow",
    route: "WorkFlow",
    slug: "WorkFlow",
    title: "WorkFlow",
  },
  {
    element: <WorkFlowDetail />,
    path: "/WorkFlow/WorkFlowDetail",
    route: "WorkFlow/WorkFlowDetail",
    slug: "WorkFlow/WorkFlowDetail",
    title: "WorkFlow/WorkFlowDetail",
  },
  {
    element: <DispatchDelivery />,
    path: "/dispatct-delivery",
    route: "dispatct-delivery",
    slug: "dispatct-delivery",
    title: "dispatct-delivery",
  },
  {
    element: <UPCDashboardInvoice />,
    path: "/upc-dashboard-invoice",
    route: "upc-dashboard-invoice",
    slug: "upc-dashboard-invoice",
    title: "upc-dashboard-invoice",
  },
  {
    element: <R116 />,
    path: "/report_116",
    route: "report_116",
    slug: "report_116",
    title: "report_116",
  },
  {
    element: <R782 />,
    path: "/report_782",
    route: "report_782",
    slug: "report_782",
    title: "report_782",
  },
  {
    element: <DispatchBooking />,
    path: "/mdr-dashboard",
    route: "mdr-dashboard",
    slug: "mdr-dashboard",
    title: "mdr-dashboard",
  },
  {
    element: <PrintForm />,
    path: "/PrintForm",
    route: "PrintForm",
    slug: "PrintForm",
    title: "PrintForm",
    meta: {
      layout: "blank",
    },
  },
  {
    element: <ComercialForm />,
    path: "/ComercialForm",
    route: "ComercialForm",
    slug: "ComercialForm",
    title: "ComercialForm",
    meta: {
      layout: "blank",
    },
  },
  {
    element: <YardReturn />,
    path: "/YardReturn",
    route: "YardReturn",
    slug: "YardReturn",
    title: "YardReturn",
    meta: {
      layout: "blank",
    },
  },
  {
    element: <OutletProfile />,
    path: "/OutletProfile",
    route: "OutletProfile",
    slug: "OutletProfile",
    title: "OutletProfile",
  },
  {
    element: <DispatchMerchandising />,
    path: "/dispatct-merchandising",
    route: "dispatct-merchandising",
    slug: "dispatct-merchandising",
    title: "dispatct-merchandising",
  },
  {
    element: <OutletProfile />,
    path: "/outlet-profile",
    route: "outlet-profile",
    slug: "outlet-profile",
    title: "outlet-profile",
  },
  {
    element: <ClosedOutlets />,
    path: "/closed-outlets",
    route: "closed-outlets",
    slug: "closed-outlets",
    title: "closed-outlets",
  },
  {
    element: <EditBlock />,
    path: "/edit-block",
    route: "edit-block",
    slug: "edit-block",
    title: "edit-block",
  },
  {
    element: <AddBlock />,
    path: "/add-block",
    route: "add-block",
    slug: "add-block",
    title: "add-block",
  },
];
export default ListRoutes;
