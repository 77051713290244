import { Fragment, useState, useEffect, useCallback } from "react";
import Select from "react-select";
import "cleave.js/dist/addons/cleave-phone.us";
import { SC } from "../../../../services/Api/serverCall";
import toast from "react-hot-toast";
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  CardTitle,
  Badge,
  Table,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import debounce from "lodash/debounce";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
import BreadCrumbs from "../../../../@core/components/breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import useDropDown from "../../../../utility/hooks/useDropDown";
import { Edit, Trash } from "react-feather";
import ConfirmationModal from "./ConfirmationModal";
const animatedComponents = makeAnimated();

const EditBlock = ({ data }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [selectedUnit, setSelectedUnit] = useState({});
  const [loader, setLoader] = useState(false);
  const [distributorsData, setDistributorsData] = useState({});
  const [regionDrop, setRegionDrop] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleConfirm = () => {
    alert("Confirmed!");
    setIsModalOpen(false);
  };
  const [inputValues, setInputValues] = useState([
    {
      id: "sndNavisionCode",
      label: "SND",
      value: "",
      username: "",
      user_id: "",
    },
    {
      id: "unitManagerNavisionCode",
      label: "UM/GM",
      value: "",
      username: "",
      user_id: "",
    },
    {
      id: "kpoNavisionCode",
      label: "KPO",
      value: "",
      username: "",
      user_id: "",
    },
    {
      id: "memManagerNavisionCode",
      label: "MEM MANAGER",
      value: "",
      username: "",
      user_id: "",
    },
    {
      id: "rsmNavisionCode",
      label: "RSM",
      value: "",
      username: "",
      user_id: "",
    },
    {
      id: "tdmNavisionCode",
      label: "TDM",
      value: "",
      username: "",
      user_id: "",
    },
  ]);
  const location = useLocation();
  const item = location.state?.item;
  const [state, setState] = useState(item?.isActive === 0 ? true : false);
  const distributorTypes = useDropDown(31);
  const distributorAreaTypes = useDropDown(32);
  const distributorUnit = useDropDown(34);
  const distributorRegion = useDropDown(33);
  const fetchPjpUserData = (data) => {
    const processElement = (element) =>
      [
        element?.sndNavisionCode &&
          getPjpUserData(element.sndNavisionCode, "sndNavisionCode"),
        element?.memManagerNavisionCode &&
          getPjpUserData(
            element.memManagerNavisionCode,
            "memManagerNavisionCode"
          ),
        element?.rsmNavisionCode &&
          getPjpUserData(element.rsmNavisionCode, "rsmNavisionCode"),
        element?.tdmNavisionCode &&
          getPjpUserData(element.tdmNavisionCode, "tdmNavisionCode"),
        element?.kpoNavisionCode &&
          getPjpUserData(element.kpoNavisionCode, "kpoNavisionCode"),
        element?.unitManagerNavisionCode &&
          getPjpUserData(
            element.unitManagerNavisionCode,
            "unitManagerNavisionCode"
          ),
      ].filter(Boolean); // Remove null/undefined values

    return Array.isArray(data)
      ? data.flatMap(processElement)
      : processElement(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      setDistributorsData({ ...item });
      setState(item?.isActive === 0 ? false : true);
      const promises = fetchPjpUserData(item);
      await Promise.all(promises);
      setLoader(false);
    };
    fetchData();
  }, [item]);

  const getPjpUserData = (value, inputID) => {
    console.log("Usman");
    return new Promise((resolve, reject) => {
      if (value === "") {
        setInputValues([
          {
            id: "sndNavisionCode",
            label: "SND",
            value: "",
            username: "",
            user_id: "",
          },
          {
            id: "unitManagerNavisionCode",
            label: "UM/GM",
            value: "",
            username: "",
            user_id: "",
          },
          {
            id: "kpoNavisionCode",
            label: "KPO",
            value: "",
            username: "",
            user_id: "",
          },
          {
            id: "memManagerNavisionCode",
            label: "MEM MANAGER",
            value: "",
            username: "",
            user_id: "",
          },
          {
            id: "rsmNavisionCode",
            label: "RSM",
            value: "",
            username: "",
            user_id: "",
          },
          {
            id: "tdmNavisionCode",
            label: "TDM",
            value: "",
            username: "",
            user_id: "",
          },
        ]);
        resolve();
        return;
      }
      console.log("2nd");
      let payload = {
        user_id: value,
      };

      SC.postCall({
        url: "portal/C01PortalGetUserDetailsByNavId",
        data: payload,
      })
        .then((res) => {
          let response = JSON.parse(JSON.stringify(res));
          console.log(
            "🚀 ~ .then ~ C01PortalGetUserDetailsByNavId:",
            response?.data?.DATA
          );
          setInputValues((prevValues) =>
            prevValues.map((input) =>
              input?.id === inputID
                ? {
                    ...input,
                    username: response?.data?.DATA?.displayName || "empty",
                    user_id: value,
                    payloadId: response?.data?.DATA?.id,
                  }
                : input
            )
          );
          resolve();
        })
        .catch((error) => {
          console.log("ERROR", error);
          reject(error);
        });
    });
  };

  const handleDebouncedChange = useCallback(
    debounce((value, inputID) => {
      if (value !== "") {
        getPjpUserData(value, inputID);
      }
    }, 1000),
    []
  );

  const onUpdateData = (key, value, payloadId = null, inputID = null) => {
    setDistributorsData((prevState) => {
      if (value === "") {
        // Prevent removing other data fields accidentally
        const newState = { ...prevState };
        delete newState[key]; // Remove only the specific key
        return newState;
      }
      return {
        ...prevState,
        [key]: value === "" ? null : payloadId || value,
      };
    });

    if (inputID !== null && value !== "") {
      handleDebouncedChange(value, inputID);
    }
  };

  const getUsernameById = (id) => {
    const foundItem = inputValues.find((item) => item.id === id);
    return foundItem?.user_id &&
      (foundItem.user_id !== "empty" || foundItem.user_id !== "")
      ? foundItem?.user_id
      : null;
  };

  function getPayloadId(id) {
    const item = inputValues.find((entry) => entry.id === id);
    return item?.payloadId ?? null;
  }

  const onSaveDistributor = () => {
    console.log("input", inputValues);
    const isEmpty = (value) =>
      value === "" || value === null || value === undefined;
    const requiredFields = [
      {
        key: "distributorName",
        message: "Name is required. Please enter a valid name.",
      },
      {
        key: "distributorDescription",
        message: "Description is required. Please provide details.",
      },
      {
        key: "typeId",
        message: "Type selection is required. Please choose a valid type.",
      },
      {
        key: "areaType",
        message:
          "Area type is required. Please select an appropriate area type.",
      },
      {
        key: "unitId",
        message: "Unit selection is required. Please select a valid unit.",
      },
      {
        key: "regionId",
        message: "Region selection is required. Please choose a valid region.",
      },
    ];
    for (const { key, message } of requiredFields) {
      if (isEmpty(distributorsData?.[key])) {
        return toast.error(message);
      }
    }
    const selectedRegion = distributorRegion.find(
      (opt) => opt.id === distributorsData?.regionId
    );
    const selectedUnit = distributorUnit.find(
      (opt) => opt.id === distributorsData?.unitId
    );
    const payload = {
      sndNavisionCode: getUsernameById("sndNavisionCode"),
      unitManagerNavisionCode: getUsernameById("unitManagerNavisionCode"),
      kpoNavisionCode: getUsernameById("kpoNavisionCode"),
      memManagerNavisionCode: getUsernameById("memManagerNavisionCode"),
      rsmNavisionCode: getUsernameById("rsmNavisionCode"),
      tdmNavisionCode: getUsernameById("tdmNavisionCode"),
      unitManagerId: getPayloadId("unitManagerNavisionCode"),
      memManagerId: getPayloadId("memManagerNavisionCode"),
      rsmId: getPayloadId("rsmNavisionCode"),
      kpoId: getPayloadId("kpoNavisionCode"),
      tdmId: getPayloadId("tdmNavisionCode"),
      sndId: getPayloadId("sndNavisionCode"),
      isActive: state === true ? 1 : 0,
      regionName: selectedRegion?.label || null,
      unitName: selectedUnit?.label || null,
      distributorName: distributorsData?.distributorName,
      areaType: distributorsData?.areaType,
      unitId: distributorsData?.unitId,
      regionId: distributorsData?.regionId,
      distributorDescription: distributorsData?.distributorDescription,
      typeId: distributorsData?.areaType === 2 ? 3 : distributorsData?.typeId,
      distributorId: distributorsData?.distributorId,
      lat: 0,
      lng: 0,
      distributorEmail: null,
      distributorAddress: null,
      distributorContact: null,
    };
    console.log("payload", payload);
    saveDistributor(payload);
  };

  const saveDistributor = (payload) => {
    SC.postCall({
      url: "portal/C01PortalSaveDistributorDetails",
      data: payload,
    })
      .then((res) => {
        console.log("Response:", res);
        navigate(-1);
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.USER_MESSAGE}`);
        console.error("ERROR", error);
      });
  };

  return (
    <Fragment>
      <BreadCrumbs
        title="Theia"
        data={[{ title: "F - 97" }, { title: "Edit Block" }]}
        onPress={() => navigate(-1)}
        onPressReport={() => {}}
      />
      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
        <>
          <Card>
            <CardBody>
              <Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    alignSelf: "flex-end",
                    paddingRight: 10,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    Status
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="form-check form-switch"
                  >
                    <Input
                      type="switch"
                      id="switch-success"
                      name="success"
                      checked={state}
                      onChange={() => setState(!state)}
                      style={{
                        transform: "scale(0.75)",
                        WebkitTransform: "scale(0.75)",
                        backgroundColor: state ? "green" : "gray",
                        borderColor: state ? "green" : "gray",
                      }}
                    />
                  </div>
                </div>
                <Row className="gy-1 pt-75">
                  <Col md="4">
                    <Col md="12" style={{ marginBottom: 10 }}>
                      <Label className="form-label" for="firstName">
                        ID
                      </Label>
                      <Input
                        disabled
                        id="firstName"
                        placeholder="John"
                        onChange={(e) =>
                          onUpdateData("distributorName", e?.target?.value)
                        }
                        value={distributorsData?.distributorId}
                      />
                    </Col>
                    <Col md="12" style={{ marginBottom: 10 }}>
                      <Label className="form-label" for="firstName">
                        Name
                      </Label>
                      <Input
                        id="firstName"
                        placeholder="John"
                        onChange={(e) =>
                          onUpdateData("distributorName", e?.target?.value)
                        }
                        value={distributorsData?.distributorName}
                      />
                    </Col>
                    <Col md="12" style={{ marginBottom: 10 }}>
                      <Label className="form-label" for="firstName">
                        Description
                      </Label>
                      <Input
                        id="firstName"
                        placeholder="John"
                        onChange={(e) =>
                          onUpdateData(
                            "distributorDescription",
                            e?.target?.value
                          )
                        }
                        value={distributorsData?.distributorDescription}
                      />
                    </Col>
                    <Col md="12" style={{ marginBottom: 10 }}>
                      <Label className="form-label" for="firstName">
                        Area Type
                      </Label>
                      <Select
                        isClearable={false}
                        theme={selectThemeColors}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={[]}
                        isMulti={false}
                        options={distributorAreaTypes || []}
                        value={distributorAreaTypes.find(
                          (option) => option.id === distributorsData?.areaType
                        )}
                        className="react-select"
                        classNamePrefix="select"
                        onChange={(r) => onUpdateData("areaType", r?.id)}
                      />
                    </Col>
                    <Col md="12" style={{ marginBottom: 10 }}>
                      <Label className="form-label" for="firstName">
                        Type
                      </Label>
                      <Select
                        isClearable={false}
                        theme={selectThemeColors}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={[]}
                        isMulti={false}
                        options={
                          distributorsData?.areaType === 2
                            ? distributorTypes.filter(
                                (type) => type.value === "Direct Sales"
                              )
                            : distributorTypes || []
                        }
                        value={
                          distributorsData?.areaType === 2
                            ? {
                                value: "Direct Sales",
                                label: "Direct Sales",
                                id: 3,
                              }
                            : distributorTypes.find(
                                (option) =>
                                  option.id === distributorsData?.typeId
                              )
                        }
                        className="react-select"
                        classNamePrefix="select"
                        onChange={(r) => onUpdateData("typeId", r?.id)}
                      />
                    </Col>
                    <Col md="12" style={{ marginBottom: 10 }}>
                      <Label className="form-label" for="firstName">
                        Unit / Plant
                      </Label>
                      <Select
                        isClearable={false}
                        theme={selectThemeColors}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={[]}
                        isMulti={false}
                        options={distributorUnit || []}
                        value={distributorUnit.find(
                          (option) => option.id === distributorsData?.unitId
                        )}
                        className="react-select"
                        classNamePrefix="select"
                        onChange={(r) => {
                          onUpdateData("unitId", r?.id);
                          setSelectedUnit(r);
                        }}
                      />
                    </Col>
                    <Col md="12" style={{ marginBottom: 10 }}>
                      <Label className="form-label" for="firstName">
                        Region
                      </Label>
                      <Select
                        isClearable={false}
                        theme={selectThemeColors}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        defaultValue={[]}
                        isMulti={false}
                        options={distributorRegion || []}
                        value={distributorRegion.find(
                          (option) => option.id === distributorsData?.regionId
                        )}
                        className="react-select"
                        classNamePrefix="select"
                        onChange={(r) => {
                          onUpdateData("regionId", r?.id);
                          setSelectedRegion(r);
                        }}
                      />
                    </Col>
                  </Col>

                  {/* DIVIDER */}
                  <Col md="1" style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "1px",
                        height: "100%",
                        backgroundColor: "#EBE9F1",
                        margin: "0 auto",
                      }}
                    />
                  </Col>

                  {/* INPUTS */}
                  <Col md="7">
                    <Row className="d-flex align-items-center">
                      {Array.isArray(inputValues) &&
                        inputValues.map((input, index) => (
                          <Row
                            className="d-flex align-items-center"
                            key={index}
                          >
                            <Col md={3}>
                              <Label className="form-label" htmlFor={input?.id}>
                                {input?.label}
                              </Label>
                              <Input
                                type="text"
                                id={input?.id}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setInputValues((prev) => {
                                    const updatedInputs = prev.map((item, i) =>
                                      i === index
                                        ? {
                                            ...item,
                                            user_id: newValue,
                                            username:
                                              newValue === ""
                                                ? "empty"
                                                : item.username,
                                            payloadId: null,
                                          }
                                        : item
                                    );

                                    // Ensure that we always update based on the latest value
                                    const updatedItem = updatedInputs[index];

                                    switch (input.id) {
                                      case "sndNavisionCode":
                                        onUpdateData(
                                          "sndNavisionCode",
                                          newValue,
                                          updatedItem?.sndId,
                                          input.id
                                        );
                                        break;
                                      case "kpoNavisionCode":
                                        onUpdateData(
                                          "kpoNavisionCode",
                                          newValue,
                                          updatedItem?.kpoId,
                                          input.id
                                        );
                                        break;
                                      case "memManagerNavisionCode":
                                        onUpdateData(
                                          "memManagerNavisionCode",
                                          newValue,
                                          updatedItem?.memManagerId,
                                          input.id
                                        );
                                        break;
                                      case "rsmNavisionCode":
                                        onUpdateData(
                                          "rsmNavisionCode",
                                          newValue,
                                          updatedItem?.rsmId,
                                          input.id
                                        );
                                        break;
                                      case "tdmNavisionCode":
                                        onUpdateData(
                                          "tdmNavisionCode",
                                          newValue,
                                          updatedItem?.tdmId,
                                          input.id
                                        );
                                        break;
                                      case "unitManagerNavisionCode":
                                        onUpdateData(
                                          "unitManagerNavisionCode",
                                          newValue,
                                          updatedItem?.unitManagerId,
                                          input.id
                                        );
                                        break;
                                      default:
                                        break;
                                    }

                                    return updatedInputs;
                                  });
                                }}
                                value={input.user_id || ""}
                              />
                            </Col>
                            <Col md={7}>
                              <Input
                                type="text"
                                style={{
                                  marginTop: 25,
                                  color:
                                    input.username === "empty"
                                      ? "red"
                                      : "#0E72AD",
                                }}
                                value={
                                  input.username === "empty"
                                    ? "Not Found"
                                    : input.username || ""
                                }
                                readOnly
                              />
                            </Col>
                          </Row>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </Form>
              <Col md="auto" style={{ marginTop: 20 }}>
                <Button
                  style={{ marginRight: 20 }}
                  color="primary"
                  size="lg"
                  type="submit"
                  onClick={() => setIsModalOpen(true)}
                >
                  Update
                </Button>
              </Col>
            </CardBody>
          </Card>
        </>
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        onConfirm={onSaveDistributor}
        onClose={() => setIsModalOpen(false)}
        message="Are you sure you want to proceed?"
      />
    </Fragment>
  );
};

export default EditBlock;
