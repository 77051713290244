import { lazy } from "react";
const Features = lazy(() => import("../../views/pages/FormList"));

const DynamicRoutes = [
  {
    element: <Features />,
    path: "/",
    route: "dashboard",
    slug: "dashboard",
    title: "dashboard",
  },
  {
    element: <Features />,
    path: "/featuesList",
    route: "featuesList",
    slug: "featuesList",
    title: "featuesList",
  },
];
export default DynamicRoutes;
