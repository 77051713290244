import axios from "axios";
import { authHeader } from "./authHeader";
import errorHandler from "./errorHandler";
import toast from "react-hot-toast";
import { getPublicIp } from "../../utility/Utils";

function generateNumericBrowserId() {
  const userAgent = navigator.userAgent;
  let hash = 0;

  for (let i = 0; i < userAgent.length; i++) {
    const char = userAgent.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }

  return Math.abs(hash);
}

const baseUrl = process.env.REACT_APP_BASEURL;
const version = process.env.REACT_APP_VERSION;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (error.response?.status === 401 && !window.location?.href?.includes("/login")) {
        localStorage.clear();
        toast.error("Session expired.");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) { }
    return Promise.reject(error);
  }
);

export const SC = {
  getCall,
  postCall,
  putCall,
  deleteCall,
  postCallWithoutAuth,
  getCallWithId,
  postAttachment,
};

const handleVersionErrors = (error) => {
  let err = errorHandler(error);
  if (err === "Version Not Matched.") {
    window.location.reload();
  }
};

// Updated functions

async function getCall({ url, customUrl, page, params, customToken, responseType }) {
  params = {
    ...params,
    localIp: await getPublicIp(),
    browserId: generateNumericBrowserId(),
  };

  const requestOptions = {
    method: "GET",
    headers: authHeader(customToken),
    params,
    responseType,
  };

  return axios
    .get(customUrl ? customUrl : baseUrl + url, requestOptions)
    .then((response) => response)
    .catch((error) => {
      handleVersionErrors(error);
      return Promise.reject(error);
    });
}

async function postCall({ url, customUrl, data, callbackProgressUpload = null, source }) {
  const isFormData = data instanceof FormData;
  if (isFormData) {
    data.append('localIp', await getPublicIp());
    data.append('browserId', generateNumericBrowserId());
  } else {
    data = {
      ...data,
      localIp: await getPublicIp(),
      browserId: generateNumericBrowserId(),
    };
  }
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    onUploadProgress: (progressEvent) => {
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };
  if (source) {
    requestOptions.cancelToken = source.token;
  }
  return axios
    .post(customUrl ? customUrl : baseUrl + url, data, requestOptions)
    .then((response) => response)
    .catch((error) => {
      handleVersionErrors(error);
      return Promise.reject(error);
    });
}


async function postAttachment({ url, data, callbackProgressUpload = null, source }) {
  const isFormData = data instanceof FormData;

  data = {
    ...data,
    localIp: await getPublicIp(),
    browserId: generateNumericBrowserId(),
  };

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    onUploadProgress: (progressEvent) => {
      if (callbackProgressUpload) callbackProgressUpload(progressEvent);
    },
  };

  if (!isFormData) {
    requestOptions.headers["Content-Type"] = "application/json";
  }

  if (source) {
    requestOptions.cancelToken = source.token;
  }

  return axios
    .post(url, data, requestOptions)
    .then((response) => response)
    .catch((error) => {
      handleVersionErrors(error);
      return Promise.reject(error);
    });
}

async function putCall({ url, customUrl, data }) {
  const isFormData = data instanceof FormData;

  data = {
    ...data,
    localIp: await getPublicIp(),
    browserId: generateNumericBrowserId(),
  };

  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
  };

  if (!isFormData) {
    requestOptions.headers["Content-Type"] = "application/json";
  }

  return axios
    .put(customUrl ? customUrl + `?version=${version}` : baseUrl + url + `?version=${version}`, data, requestOptions)
    .then((response) => response)
    .catch((error) => {
      handleVersionErrors(error);
      return Promise.reject(error);
    });
}

async function deleteCall({ customUrl, url }) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    params: {
      localIp: await getPublicIp(),
      browserId: generateNumericBrowserId(),
    },
  };

  return axios
    .delete(customUrl ? customUrl + `?version=${version}` : baseUrl + url + `?version=${version}`, requestOptions)
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCallWithoutAuth({ url, customUrl, data }) {
  const isFormData = data instanceof FormData;

  data = {
    ...data,
    localIp: await getPublicIp(),
    browserId: generateNumericBrowserId(),
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": isFormData ? undefined : "application/json",
    },
  };

  return axios
    .post(customUrl ? customUrl + `?version=${version}` : baseUrl + url + `?version=${version}`, data, requestOptions)
    .then((response) => response)
    .catch((error) => {
      handleVersionErrors(error);
      return Promise.reject(error);
    });
}

async function getCallWithId({ url, customUrl, id }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    params: {
      localIp: await getPublicIp(),
      browserId: generateNumericBrowserId(),
    },
  };

  return axios
    .get(customUrl ? customUrl + url + "/" + id + `?version=${version}` : baseUrl + url + "/" + id + `?version=${version}`, requestOptions)
    .then((response) => response)
    .catch((error) => {
      handleVersionErrors(error);
      return Promise.reject(error);
    });
}
