// ** React Imports
import { Outlet } from "react-router-dom";
import {
  inventory,
  report_center,
  administration,
  sales,
  synchronization,
  merchandising,
  DashboardIcon,
  MartketWatchIcon,
  LeaderBoardIcon,
  IndividualDashboardIcon,
  MemIcon,
} from "../assets/wasfaty/SVG";
import Layout from "@layouts/VerticalLayout";
import navigation from "@src/navigation/vertical";
import { useEffect, useState } from "react";

const VerticalLayout = (props) => {
  const [sideBarList, setSideBarList] = useState([]);
  const iconMapping = {
    inventory: inventory,
    report_center: report_center,
    sales: sales,
    inventory: inventory,
    administration: administration,
    synchronization: synchronization,
    merchandising: merchandising,
    dashboards: DashboardIcon,
    leaderboards: LeaderBoardIcon,
    individualdashboard: IndividualDashboardIcon,
    mem: MemIcon,
    marketWatch: MartketWatchIcon,
  };

  useEffect(() => {
    getSideBarData();
  }, []);

  const getSideBarData = async () => {
    let features_groups = JSON.parse(localStorage?.getItem("features_groups"));
    const regions = features_groups?.user_scope_regions;
    if (features_groups !== null) {
      const dynamicNav = features_groups?.feature_groups?.map((item) => {
        return {
          id: item?.slugs,
          slug: item?.slugs,
          title: item?.groupName,
          navLink:
            item?.groupId === 26 ? "/" : `/${item?.slugs}/${item?.groupId}`,
          icon: () => iconMapping[item?.slugs] || (() => [item?.slugs]),
          ...(item?.slugs === "dashboards" &&
            regions?.length > 1 && {
              children: regions?.map((region) => ({
                id: region?.regionShortName,
                slug: region?.regionShortName,
                title: region?.regionName,
                navLink: `/dashboard/${region?.regionId}`,
              })),
            }),
        };
      });
      console.log("=-=-dynamicNav", dynamicNav);
      setSideBarList(dynamicNav);
    }
  };

  // Main Usman
  // const getSideBarData = async () => {
  //   let features_groups = JSON.parse(localStorage?.getItem("features_groups"));
  //   if (features_groups !== null) {
  //     let dynamicNav = [
  //       ...features_groups?.feature_groups?.map((item) => ({
  //         id: item?.slugs,
  //         slug: item?.slugs,
  //         title: item?.groupName,
  //         navLink:
  //           item?.groupId === 26
  //             ? "/"
  //             : "/" + item?.slugs + "/" + item?.groupId,
  //         icon: () => iconMapping[item?.slugs] || (() => [item?.slugs]),
  //       })),
  //     ];
  //     console.log("=-=-dynamicNav", dynamicNav);
  //     setSideBarList(dynamicNav);
  //   }
  // };

  return (
    <Layout menuData={[...navigation, ...sideBarList]} {...props}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
