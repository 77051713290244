import React from "react";
import Lottie from "lottie-react";
import loaderAnimation from "../../../assets/loader/LogoAnimation.json";

function Loader() {
  return (
    <div
      style={{
        height: "50vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <>
        <Lottie
          animationData={loaderAnimation}
          loop
          style={{
            width: "15vw",
            height: "15vw",
            maxWidth: "150px",
            maxHeight: "150px",
          }}
        />
      </>
    </div>
  );
}

export default Loader;
