// ** React Imports
import { lazy } from "react";
import Login from "../../views/AuthScreens/Login";
const Page404 = lazy(() => import("../../views/pages/Other/Page404"));

const AuthenticationRoutes = [
  {
    path: "/login",
    element: <Login />,
    route: "",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true,
    },
    isAuth: true,
  },
  {
    element: <Page404 />,
    path: "*",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false,
    },
    isAuth: true,
  },
];

export default AuthenticationRoutes;
